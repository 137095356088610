export const containsHTML = str => /<[a-z][\s\S]*>/i.test(str);

export const toKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');

/**
 * Remove HTML tags from string.
 */
export const stripHtmlTags = str => str?.replace(/<[^>]*>/g, '');

/**
 * Unescapes HTML special chars
 */
export const unescapeHtml = str =>
  str &&
  str
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"');

export const replaceCharacterSpacesWithSpace = str => str.replace(/&nbsp;/g, ' ');

export const maskNumber = (pattern, value) => {
  if (isNaN(parseFloat(value))) {
    return '';
  }

  let index = 0;
  const str = String(value);

  return pattern.replace(/9/g, () => {
    const char = str.at(index) || '';
    index += 1;
    return char;
  });
};

export const addDashes = sentence => {
  if (sentence) {
    const words = sentence.split(' ');
    return words.join('-');
  }
  return '';
};

/**
 * Compares two strings case-insensitively.
 *
 * @param {string} string1 - The first string to compare.
 * @param {string} string2 - The second string to compare.
 * @returns {boolean} Returns true if the strings are equal (case-insensitive), false otherwise.
 */
export const compareStringCaseInsensitive = (string1, string2) => string1.toLowerCase() === string2.toLowerCase();

export const removeSearchTermFromString = string => string.replace(encodeURIComponent(globalThis?.window?.location?.search), '').replace(globalThis?.window?.location?.search, '');

export const capitalize = s => s[0].toUpperCase() + s.slice(1);
